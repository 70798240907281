import React from 'react'

const Divider = ({ data: { showStroke, space } }) => {
  const getClasses = () => {
    let classes = showStroke ? 'opacity-20' : 'opacity-0'
    switch (space) {
      case 'sm':
        classes = `${classes} my-[15px]`
        break
      case 'lg':
        classes = `${classes} my-[50px]`
        break
      default:
        classes = `${classes} my-[25px]`
        break
    }
    return classes
  }

  return <hr className={getClasses()} />
}

export default Divider
